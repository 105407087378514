<template>
  <v-container>
    <!-- <v-row justify="center">
      <v-col cols ="12" sm="12" md="9" lg="7" xl="6">
        <pdf src="http://localhost:8080/Files/cartavacas.pdf" :page="1" style="width: 100%; border-style:solid; border-width: 1px; border-color: silver"></pdf>
      </v-col>
    </v-row> -->

    <v-row justify="center">
      <!-- <v-col cols ="12" sm="9" md="6" lg="5" xl="9"> -->
      <v-col cols ="auto">
        <!-- <VueSignaturePad id="signature" width="100%" height="1000px" ref="signaturePad" :images="[require('./LogoTostadosGrande.png')]" /> -->
        <VueSignaturePad id="signaturePad" width="800px" height="1000px" ref="signaturePad" />
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="auto" style="text-align:center">
          <div style="width: 800px">
            <div style="inline-block">
              <v-btn small width="100" class="mr-2" @click="undo">Deshacer</v-btn>
              <v-btn small width="100" class="mr-14" @click="clear">Borrar</v-btn>
              <!-- <v-btn color="primary" small width="100" class="mr-14" @click="save">Grabar</v-btn> -->
              <v-btn color="success" class="mr-2" small width="150" @click="iniciarFirma">Iniciar firma</v-btn>
              <v-btn color="warning" small width="150" @click="finalizarFirma">Finalizar firma</v-btn>
            </div>
          </div>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="auto" style="text-align;center">
          <v-checkbox
              class="mb-3"
              hide-details
              v-model="respuesta"
              label="Marca la casilla para confirmar asistencia al reconocimiento médico">
          </v-checkbox>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="auto" style="text-align;center">
          <div style="width: 800px">
            <div style="inline-block">

              <v-text-field
                outlined
                v-show="codigoConfirmacionEnviado"
                v-model="codigoConfirmacion"
                label="Código"
                style="width; 150px; inline-block; display: inline-block"
                class="mr-2"
                dense
              ></v-text-field>
              
              <v-btn color="primary" width="100" class="mr-4" @click="grabar">Grabar</v-btn>
              <v-btn width="100" @click="cancelar">Cancelar</v-btn>
            </div>
          </div>
      </v-col>
    </v-row>

  </v-container>
</template>
<script>
  // import pdf from 'vue-pdf'
  import { mapState } from 'vuex';
  import axios from 'axios';
  import Vue from 'vue';
  import VueSignaturePad from 'vue-signature-pad';
  import router from '../router'

  Vue.use(VueSignaturePad);
  export default {
    components: {
      // pdf
    },
    computed:{
        ...mapState(['empId', 'perId', 'urlRaiz']),
        ...mapState('DocumentosFirmar', ['entidadActual']),
        isReconocimientoMedico: function () {
          if (this.entidadActual == null) return false;
          return this.entidadActual.codtipo == 5;
        }
    },
    data () {
      return {
        rutaFile: '',
        codigoConfirmacionEnviado: false,
        codigoConfirmacionGenerado: '',
        codigoConfirmacion: '',
        respuesta: false
      }
    },
    methods: {
      onBegin() {
      // alert('Inicia firma');
      },
      onEnd() {
      //  alert('Finaliza firma');
      },
      undo() {
        this.$refs.signaturePad.undoSignature();
      },
      loadSignaturePadContent() {
        const canvas = this.$refs.signaturePad.signaturePad.canvas;
        // var ctx = canvas.getContext('2d');

        let background = new Image();
        background.crossOrigin="anonymous";
        // background.src = 'http://localhost:8080/Files/Factura.jpg';
        // background.src = 'http://extranet.flamenandco.es:24853/Files/Factura.jpg';
        background.src = this.rutaFile;

        let proporcion;
        background.decode()
        .then(() => {
          proporcion = background.height / background.width;

          const imageWidth = canvas.width/window.devicePixelRatio;
          const imageHeight = (canvas.width/window.devicePixelRatio) * proporcion;

          this.$refs.signaturePad.fromDataURL(this.rutaFile, {ratio: window.devicePixelRatio, width: imageWidth, height: imageHeight});

          //  this.$refs.signaturePad.fromDataURL(this.rutaFile);
          //  this.$refs.signaturePad.fromDataURL('http://extranet.flamenandco.es:24853/Files/Factura.jpg', {ratio: window.devicePixelRatio, width: imageWidth, height: imageHeight});
          //  ctx.drawImage(background, 0,0)
          // var pattern = ctx.createPattern(background, 'repeat');           
          // ctx.fillStyle = pattern;
          // ctx.fillRect(0,0, 200, 200) ;
        });

        this.$refs.signaturePad.lockSignaturePad();
      },
      clear() {
        this.$refs.signaturePad.clearSignature();
      },
      iniciarFirma() {
        this.loadSignaturePadContent();
        this.$refs.signaturePad.openSignaturePad();
      },
      finalizarFirma() {
        // this.loadSignaturePadContent();
        this.$refs.signaturePad.lockSignaturePad();
      },
      grabar() {
        this.$refs.signaturePad.lockSignaturePad();
        const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
        if (isEmpty) {
          if (!this.isReconocimientoMedico || !this.respuesta)
          {
            alert('La firma está vacía');
            return;
          }
          const controllerParameters = {
            Action: 'SAVE_NO_FIRMADO',
            codCarta: this.entidadActual.codcarta.trim(),
            Respuesta: this.respuesta
          };
          const AuthToken = localStorage.getItem('token');
            axios({ method: 'POST', 'url': this.urlRaiz + '/api/per', 'data': controllerParameters, 'headers': {"content-type": "application/json", "Authorization": AuthToken } })
            .then(result => {
                if (result.status >= 200 && result.status <= 299){
                  alert('Guardado correctamente');
                  router.push('/documentos-pendientes-firmar');
                }
            })
            .catch(error => {
                alert(error);
            })
        }
        else {

          // if (!this.codigoConfirmacionEnviado) {
            // var controllerParameters1 = {
                // Action: 'ENVIAR_CODIGO_CONFIRMACION',
                // PerId: this.perId
            // };       

            // var AuthToken1 = localStorage.getItem('token');
            // axios({ method: 'POST', 'url': this.urlRaiz + '/api/per', 'data': controllerParameters1, 'headers': {"content-type": "application/json", "Authorization": AuthToken1 } })
            // .then(result => {
                // if (result.status >= 200 && result.status <= 299){
                //  const codigoGenerado = result.data.CodigoConfirmacion;
                  // this.codigoConfirmacionGenerado = result.data.CodigoConfirmacion; // codigoGenerado;
                  // this.codigoConfirmacionEnviado = true;
                  // alert('Hemos envíado un código de seguridad por email. Introdúzcalo en la casilla junto al botón de grabar y púlselo de nuevo')
                // }
              // })
              // .catch(error => {
                  // alert(error);
              // })
          // }

          // if (this.codigoConfirmacionEnviado) {
          //   if (this.codigoConfirmacion == null || this.codigoConfirmacion == '') {
          //     alert('Debe introducir el código de confirmación que le hemos enviado por email');
          //   }
          //   else {
              // if ( this.codigoConfirmacionGenerado != null && this.codigoConfirmacionGenerado != '' && this.codigoConfirmacion.trim() == this.codigoConfirmacionGenerado) {

            const controllerParameters2 = {
                Action: 'SAVE_FIRMA',
                firmaData: data,
                codCarta: this.entidadActual.codcarta.trim(),
                PerId: this.perId,
                Respuesta: this.respuesta,
                Desarrollo: this.$store.state.desarrollo
            }; 

            const AuthToken2 = localStorage.getItem('token');
            axios({ method: 'POST', 'url': this.urlRaiz + '/api/per', 'data': controllerParameters2, 'headers': {"content-type": "application/json", "Authorization": AuthToken2 } })
            .then(result => {
                if (result.status >= 200 && result.status <= 299){
                  alert('Documento firmado correctamente');
                  router.push('/documentos-pendientes-firmar');
                }
            })
            .catch(error => {
                alert(error);
            })

              // }
              // else {
              //   alert('Elcódigo introducido es incorrecto');
              // }
            // }
          // }
        }
      },
      cancelar(){
        router.push('/documentos-pendientes-firmar');
      },
    },
    mounted() {
      let raizRutaFile = '';
      if (this.$store.state.desarrollo) {
        if (this.$store.state.local) {
          //raizRutaFile = 'http://localhost:8080/Docs/jpg';
          // raizRutaFile = 'http://localhost:25853/RepositorioFirmas/jpg';
          raizRutaFile = 'https://flamendancostorage01.file.core.windows.net/datos/Docs/Cartas/pendientes';

        }
        else {
          // raizRutaFile = 'http://extranet.flamenandco.es:24852/Docs';
          raizRutaFile = 'https://extranet.flamenandco.es:25852/RepositorioFirmas/jpg';
        }
      }
      else {
        // raizRutaFile = 'http://extranet.flamenandco.es:23853/Docs';
        //raizRutaFile = 'https://extranet.flamenandco.es:24852/RepositorioFirmas/jpg';
        //raizRutaFile = '//10.0.0.4/vfp/nominas/CARTAS/jpg';
        raizRutaFile = 'https://flamendancostorage01.file.core.windows.net/datos/Docs/Cartas/pendientes';
      }
      // var raizRutaFile = this.urlRaiz + '/RepositorioFirmas/jpg';

      this.rutaFile = raizRutaFile + '/' + this.entidadActual.codcarta.trim() + '.jpg' + '?' + 'sv=2022-11-02&ss=f&srt=o&sp=r&se=2100-05-30T16:30:40Z&st=2024-05-30T08:30:40Z&spr=https&sig=C6H0qHt1bkVeFKXkuaD2QWrLlfmABquhK%2FjYSSQEfOc%3D';
      this.$refs.signaturePad.signaturePad.canvas.style.backgroundImage = "url('" + this.rutaFile + "')";
      this.loadSignaturePadContent();

      // background.src = 'http://localhost:8080/Files/Factura.jpg';
      // background.src = 'http://extranet.flamenandco.es:24853/Files/Factura.jpg';
    },
    created() {
      // function resizeCanvas() {
      //     var ratio =  Math.max(window.devicePixelRatio || 1, 1);
      //     canvas.width = canvas.offsetWidth * ratio;
      //     canvas.height = canvas.offsetHeight * ratio;
      //     canvas.getContext('2d').scale(ratio, ratio);
      //     signaturePad.clear(); // otherwise isEmpty() might return incorrect value
      // }

      //     window.addEventListener('resize', resizeCanvas);
      // resizeCanvas();      
    },
    updated() {
    }
  }
</script>
<style>
  #signature {
    border-style: solid;
    border-width: 2px;
    border-color: grey;
    /* background-image: url('http://localhost:8080/Files/Factura.jpg'); */
    /* background-image: url({{rutaFile}}); */
    background-size: 800px 1000px; 
    background-position: center;
    background-origin: border-box;
    background-clip: content-box, border-box;
  }  
</style>